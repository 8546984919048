<template>
  <div>
    <div>
      <system-bar></system-bar>
      <inspector-menu></inspector-menu>
    </div>
    <div>
      <v-card>
        <v-card-text>
          <signup-form @signupSuccess="saveSuccess" v-bind:inspectorId="inspectorId"> </signup-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import SystemBar from "../../library/system-bar/system-bar";
import InspectorMenu from "../menubar/menubar";
import SignupForm from "../sign-up/sign-up";
import { mapGetters } from "vuex";
export default {
  components: {
    "signup-form": SignupForm,
    "inspector-menu": InspectorMenu,
    "system-bar": SystemBar,
  },
  name: "PROFILE",
  data() {
    return {
      url: process.env.VUE_APP_LOYALTY_API_URI,
      custId: process.env.VUE_APP_OGF_CUST_ID,
      inspectorId: "",
    };
  },
  computed: {
    ...mapGetters({}),
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      console.log("mounted");
    },
    saveSuccess(response) {
      console.log(response);
      alert("Saved");
      //this.$router.push("/inspector/dashboard");
    },
  },
};
</script>
